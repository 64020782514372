import React from "react";

const ButtonBmc = () => {
  return (
    <div>
      <a href="https://www.buymeacoffee.com/wendevwebA">
        <img
          src="https://img.buymeacoffee.com/button-api/?text=Un petit café ? ☕&emoji=&slug=wendevwebA&button_colour=7e42e1&font_colour=ffffff&font_family=Poppins&outline_colour=ffffff&coffee_colour=FFDD00"
          alt="Bouton Buy me a coffee"
        />
      </a>
    </div>
  );
};

export default ButtonBmc;
